@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Lato';
  }
}

:root {
  --toastify-color-success: #bec500 !important;
}
